//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import qs from 'qs';
import Form from './GroupForm.vue';
import GroupLPModal from './GroupLPModal.vue';
export default {
  name: 'LPRUSLPManagementGroup',
  components: {
    Form: Form,
    GroupLPModal: GroupLPModal
  },
  data: function data() {
    return {
      show_list: true,
      search_text: null,
      FIELDS: [{
        key: 'name',
        label: this.$t('cloud.title.group_name')
      }, {
        key: 'memo',
        label: this.$t('cloud.title.note')
      }, {
        key: 'lp_cnt',
        label: this.$t('cloud.title.lpr_lp_cnt')
      }, {
        key: 'ops',
        label: this.$t('cloud.title.operation'),
        _style: 'width:100px;text-align:center;',
        sorter: false
      }],
      list: [],
      ipp: 10,
      sort_column: 'id',
      sort_order: 'asc',
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      initData: null
    };
  },
  mounted: function mounted() {
    this.fetchInitData();
  },
  methods: {
    fetchInitData: function fetchInitData() {
      this.getData();
    },
    getData: function getData() {
      var _this = this;

      var params = {};
      params.page = this.pageCurrent;
      params.ipp = this.ipp;
      params.sort_column = this.sort_column;
      params.sort_order = this.sort_order;
      params.include_lp_cnt = 1;

      if (this.search_text) {
        params.search = this.search_text;
      }

      var query_string = '?' + qs.stringify(params);
      axios.get("/api/lpr/us/group/".concat(query_string)).then(function (result) {
        _this.pageTotal = result.data.page.total;
        _this.pageCurrent = result.data.page.current;
        _this.count = result.data.page.count;
        _this.list = JSON.parse(JSON.stringify(result.data.list));
      }).catch(function (error) {
        console.error(error);
      });
    },
    onAdd: function onAdd() {
      this.initData = null;
      this.show_list = false;
    },
    onDelete: function onDelete(id) {
      var _this2 = this;

      this.$confirm(this.$t('msgbox.confirm.content'), this.$t('msgbox.confirm.title'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        axios.delete("/api/lpr/us/group/".concat(id, "/")).then(function () {
          _this2.$notify.success({
            title: _this2.$t('msgbox.request_ok_title'),
            message: _this2.$t('msgbox.request_ok_content'),
            offset: 30
          });

          _this2.getData();
        }).catch(function (error) {
          console.error(error);

          _this2.$notify.error({
            title: _this2.$t('msgbox.request_fail_title'),
            message: _this2.$t('msgbox.request_fail_content'),
            offset: 30
          });
        });
      }).catch(function () {});
    },
    onEdit: function onEdit(item) {
      this.initData = this.$utility.copy(item);
      this.show_list = false;
    },
    onPayload: function onPayload(payload) {
      var _this3 = this;

      var req = null;

      if (payload.id) {
        req = axios.put("/api/lpr/us/group/".concat(payload.id, "/"), payload);
      } else {
        req = axios.post("/api/lpr/us/group/", payload);
      }

      req.then(function () {
        _this3.$notify.success({
          title: _this3.$t('msgbox.request_ok_title'),
          message: _this3.$t('msgbox.request_ok_content'),
          offset: 30
        });

        _this3.show_list = true;

        _this3.getData();
      }).catch(function (error) {
        console.error(error);

        _this3.$notify.error({
          title: _this3.$t('msgbox.request_fail_title'),
          message: _this3.$t('msgbox.request_fail_content'),
          offset: 30
        });
      });
    },
    onUpdatePage: function onUpdatePage() {
      this.pageDirectSet = "" + this.pageCurrent;
      this.getData();
    },
    onChangePageDirectSet: function onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = "" + this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getData();
    },
    onSearch: function onSearch(text) {
      this.search_text = text;
      this.getData();
    },
    onOps: function onOps(cmd, index, item) {
      if (cmd === 'add_lp') {
        this.$refs.GroupLPModal.open({
          group: item,
          is_add: true
        });
      } else if (cmd === 'del_lp') {
        this.$refs.GroupLPModal.open({
          group: item,
          is_add: false
        });
      } else if (cmd === 'edit') {
        this.onEdit(item);
      } else if (cmd === 'delete') {
        this.onDelete(item.id);
      }
    }
  }
};