function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import axios from 'axios';
import Search from '@/containers/Search.vue';
export default {
  name: 'LPRUSGroupLPModal',
  components: {
    Search: Search
  },
  data: function data() {
    return {
      loading: false,
      show: false,
      cb_ok: null,
      cb_cancel: null,
      search_text: null,
      is_add: true,
      group: {
        id: null,
        name: null,
        memo: "",
        lps: {
          list: []
        }
      },
      check_all: false,
      add_list: [],
      list: [],
      ipp: 10,
      sort_column: 'id',
      sort_order: 'asc',
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      FIELDS: [{
        key: 'boxcheck',
        label: ''
      }, {
        key: 'plate',
        label: this.$t('cloud.title.lp_number')
      }, {
        key: 'memo',
        label: this.$t('cloud.title.note')
      }]
    };
  },
  methods: {
    open: function open(param, cb_ok, cb_cancel) {
      this.is_add = param.is_add;
      this.group = this.$utility.copy(param.group);
      this.show = true;
      this.cb_ok = cb_ok;
      this.cb_cancel = cb_cancel;
      this.getData();
    },
    getData: function getData() {
      var _this = this;

      var params = {};
      params.page = this.pageCurrent;
      params.ipp = this.ipp;
      params.sort_column = this.sort_column;
      params.sort_order = this.sort_order;

      if (this.group.id) {
        if (this.is_add) {
          params.ex_group = this.group.id;
        } else {
          params.in_group = this.group.id;
        }
      }

      if (this.search_text) {
        params.search = this.search_text;
      }

      var query_string = '?' + qs.stringify(params);
      this.loading = true;
      axios.get("/api/lpr/us/lp/".concat(query_string)).then(function (result) {
        _this.pageTotal = result.data.page.total;
        _this.pageCurrent = result.data.page.current;
        _this.count = result.data.page.count;
        var l = result.data.list.map(function (el) {
          if (_this.add_list.find(function (al) {
            return al.id === el.id;
          })) {
            el.checked = true;
            return el;
          }

          el.checked = false;
          return el;
        });
        _this.list = _this.$utility.copy(l);
      }).catch(function (error) {
        console.error(error);
      }).finally(function () {
        _this.loading = false;
      });
    },
    onUpdatePage: function onUpdatePage() {
      this.pageDirectSet = "" + this.pageCurrent;
      this.check_all = false;
      this.getData();
    },
    onChangePageDirectSet: function onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = "" + this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getData();
    },
    onCancel: function onCancel() {
      this.$emit('cancel');

      if (this.cb_cancel) {
        this.cb_cancel();
      }

      this.cb_ok = null;
      this.cb_cancel = null;
      this.param = null;
      this.show = false;
      this.check_all = false;
      this.add_list = [];
      this.list = [];
      this.ipp = 10;
      this.sort_column = 'id';
      this.sort_order = 'asc';
      this.pageTotal = 1;
      this.pageCurrent = 1;
      this.pageDirectSet = 1;
      this.count = 0;
    },
    onOkay: function onOkay() {
      var _this2 = this;

      this.$emit('ok', this.param);
      var data = {};
      data.group_id = this.group.id;
      data.lps = [];

      var _iterator = _createForOfIteratorHelper(this.add_list),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var lp = _step.value;
          data.lps.push(lp.id);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (this.is_add) {
        data.op = 'bind';
      } else {
        data.op = 'unbind';
      }

      axios.patch('/api/lpr/us/group/', data).then(function () {
        _this2.$emit('reload');

        _this2.$notify.success({
          title: _this2.$t('msgbox.request_ok_title'),
          message: _this2.$t('msgbox.request_ok_content'),
          offset: 30
        });
      }).catch(function () {
        _this2.$notify.error({
          title: _this2.$t('msgbox.request_fail_title'),
          message: _this2.$t('msgbox.request_fail_content'),
          offset: 30
        });
      });
      this.onCancel();
    },
    onCheck: function onCheck(lp) {
      if (lp.checked) {
        this.add_list.push(this.$utility.copy(lp));
      } else {
        var pos = this.add_list.findIndex(function (el) {
          return el.id === lp.id;
        });
        this.add_list.splice(pos, 1);
      }

      this.list = this.$utility.copy(this.list);
    },
    onRemove: function onRemove(lp) {
      var _this3 = this;

      var pos = this.add_list.findIndex(function (el) {
        return el.id === lp.id;
      });
      this.add_list.splice(pos, 1);
      var list = this.$utility.copy(this.list);
      var l = list.map(function (el) {
        if (_this3.add_list.find(function (al) {
          return al.id === el.id;
        })) {
          el.checked = true;
          return el;
        }

        el.checked = false;
        return el;
      });
      this.list = this.$utility.copy(l);
    },
    onCheckAll: function onCheckAll() {
      var _this4 = this;

      if (this.check_all) {
        var _iterator2 = _createForOfIteratorHelper(this.list),
            _step2;

        try {
          var _loop = function _loop() {
            var lp = _step2.value;

            var pos = _this4.add_list.findIndex(function (el) {
              return el.id === lp.id;
            });

            if (pos >= 0) return "continue";

            _this4.add_list.push(_this4.$utility.copy(lp));
          };

          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var _ret = _loop();

            if (_ret === "continue") continue;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      } else {
        var _iterator3 = _createForOfIteratorHelper(this.list),
            _step3;

        try {
          var _loop2 = function _loop2() {
            var lp = _step3.value;

            var pos = _this4.add_list.findIndex(function (el) {
              return el.id === lp.id;
            });

            if (pos < 0) return "continue";

            _this4.add_list.splice(pos, 1);
          };

          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _ret2 = _loop2();

            if (_ret2 === "continue") continue;
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      }

      var list = this.$utility.copy(this.list);
      var l = list.map(function (el) {
        if (_this4.add_list.find(function (al) {
          return al.id === el.id;
        })) {
          el.checked = true;
          return el;
        }

        el.checked = false;
        return el;
      });
      this.list = this.$utility.copy(l);
    },
    onSearch: function onSearch(text) {
      this.search_text = text;
      this.getData();
    }
  }
};